:root {
    --primary-color: #2C3E50;
    --accent-color: #3498DB;
    --text-color: #2C3E50;
    --light-bg: #ECF0F1;
    --transition: all 0.3s ease;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    overflow-x: hidden;
}

.hero {
    min-height: 100vh;
    background: linear-gradient(135deg, #E0EAFC 0%, #CFDEF3 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    position: relative;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 1rem 2rem;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: var(--transition);
}

.navbar.scrolled {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chord-diagram {
    width: 200px;
    height: 200px;
    margin: 0 auto;        /* Add this to center horizontally */
    margin-bottom: 2rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease forwards;
}

.chord-diagram svg {
    width: 100%;
    height: 100%;
}

h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3.5rem;
    margin-bottom: 1rem;
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease 0.2s forwards;
}

.subtitle {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    color: #555;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease 0.4s forwards;
}

.cta-container {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 4rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease 0.6s forwards;
}

.cta-button {
    padding: 1rem 2rem;
    border: none;
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition);
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.primary-cta {
    background: var(--accent-color);
    color: white;
}

.primary-cta:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(52, 152, 219, 0.3);
}

.secondary-cta {
    background: rgba(255, 255, 255, 0.9);
    color: var(--primary-color);
}

.secondary-cta:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.social-links {
    display: flex;
    justify-content: center;  /* Add this line for centering */
    gap: 1.5rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease 0.8s forwards;
}

.social-link {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: var(--primary-color);
    text-decoration: none;
    transition: var(--transition);
    font-size: 1.2rem;
}

.social-link:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.social-link.linkedin:hover { background: #0077B5; color: white; }
.social-link.youtube:hover { background: #FF0000; color: white; }
.social-link.instagram:hover { background: #E1306C; color: white; }
.social-link.email:hover { background: #34495E; color: white; }
.social-link.yelp:hover { background: #FF1A1A; color: white; }

.scroll-indicator {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: fadeInUp 0.8s ease 1s forwards, bounce 2s infinite;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-10px); }
    60% { transform: translateY(-5px); }
}

@media (max-width: 768px) {
    h1 { font-size: 2.5rem; }
    .subtitle { font-size: 1.2rem; }
    .cta-container { flex-direction: column; }
    .chord-diagram { width: 160px; height: 160px; }
}
h1 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .subtitle {
    font-size: 1.25rem;
    color: #4a5568;
    margin-bottom: 2rem;
  }
  
  .text-center {
    text-align: center;
  }