.accordion-modal{
    background: linear-gradient(270deg,#9497a5,#d3d9f2);
}
.accordion {
    margin: 1.6rem;
    margin-bottom: 1rem;
    background-color: none;
    color: rgb(0, 0, 0);
    cursor: pointer;
    padding: 18px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 18px;
    transition: 0.4s;
    font-weight:bold 
  }
  
  .active, .accordion:hover {
    background-color: rgba(219, 223, 255, 0.591); 
  }
  .accordion-items{
      width: 100%;
  }
  .panel {
    margin-left: 2rem;
    margin-bottom: 1rem;
    padding: 1rem;
    width:80%;
    background-color: rgba(255, 255, 255, 0.59);
    overflow: hidden;
    font-size: 18px;
  }
  